import { Header, HeaderBox, Overlay, Page } from "../manage/styles";
// import FormInput from "../../input";
import { getValue } from "../functions";
import { GetIcon } from "../../../../icons";
import { DataItem, DisplayInfo, Logo, Td, Title, TrBody } from "./styles";
import Tabs from "../../tab";
import React, { useCallback, useEffect, useState } from "react";
import { RowContainer } from "../../../styles/containers/styles";
// import ListTable from "../list";

import { ProfileImage } from "../styles";
import ImagePopup from "../image";

import { IconButton, Title as TitleElement } from "../../elements";
import { noimage, mobLogo } from "../../../../images";
// import CrudForm from "../create";

import { PageHeader } from "../../input/heading";
// import CrudForm from "../create";

export const DisplayInformations = ({ attributes, data, formMode, popupMenu }) => {
  const [showImage, setShowImage] = useState(false);

  return (
    <DisplayInfo className={popupMenu}>
      <TrBody className={formMode}>
        {attributes.map((attribute, index) => {
          if (attribute.view) {
            try {
              const itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem] : data[attribute.name];
              // if (attribute.type === "image") {
              //   return "";
              // }
              return (
                <Td key={index} className={attribute.type}>
                  <Title>
                    {attribute.icon?.length > 0 && <GetIcon icon={attribute.icon}></GetIcon>}
                    {attribute.label}
                  </Title>
                  {attribute.type === "image" ? (
                    <DataItem>
                      {getValue(attribute, itemValue, true, false, (src) => {
                        setShowImage(src);
                      })}
                    </DataItem>
                  ) : (
                    <DataItem>{getValue(attribute, itemValue, true)} </DataItem>
                  )}
                </Td>
              );
            } catch (error) {
              return (
                <Td key={index}>
                  <Title>{attribute.label}</Title>
                  <DataItem>{`--`} </DataItem>
                </Td>
              );
            }
          }
          if (attribute.type === "title") {
            return (
              <Td key={index} className="span">
                <TitleElement line={false} icon={attribute.icon} title={attribute.title}></TitleElement>
              </Td>
            );
          }
          return null;
        })}
      </TrBody>
      {showImage && <ImagePopup onClose={() => setShowImage(null)} src={showImage.src}></ImagePopup>}
    </DisplayInfo>
  );
};

// const ImageGallery = React.lazy(() => import("../imagegallery"));
// const CrudForm = React.lazy(() => import("../create"));
const Popup = ({ itemDescription = { type: "", name: "" }, showInfoType = "view", editData, customProfileSource, profileImage, isSingle = false, popupMode = "medium", showInfo, popupMenu, formMode, selectedMenuItem, viewMode, themeColors, openData, setLoaderBox, setMessage, closeModal, itemTitle, updatePrivilege, isEditingHandler, udpateView, parentName, parentIcon, parents = {}, parentReference }) => {
  const titleValue = (itemTitle.collection?.length > 0 ? openData?.data?.[itemTitle.collection]?.[itemTitle.name] ?? "" : openData?.data?.[itemTitle.name]) || "Please update the itemTitle.";
  const descriptionValue = (itemDescription.collection?.length > 0 ? openData?.data?.[itemDescription.collection]?.[itemDescription.name] ?? "" : openData?.data?.[itemDescription.name]) || "";

  // const MemoizedListTable = React.memo(ListTable);
  // console.log(parentReference)
  const tabHandler = useCallback(() => {
    const tempTab = openData.actions
      .filter((item) => ["subList", "subTabs", "subItem", "custom", "information", "title", "gallery"].includes(item.type))
      .map((item, index) => ({
        name: `${item.id}-${index}`,
        title: item.title,
        icon: item.icon,
        type: item.type,
        css: item.type === "information" ? "info" : "",
        content: item.content,
        element: item.tabs?.length ? null : item, // If there are tabs, we set element to null
        dynamicTabs: item.dynamicTabs ?? null,
        tabs: item.tabs
          ?.filter((item) => ["subList", "subTabs", "subItem", "custom", "information", "title", "gallery"].includes(item.type))
          .map((tabItem, index2) => ({
            name: `${tabItem.id}-${index}-${index2}`,
            title: tabItem.title,
            type: tabItem.type,
            icon: tabItem.icon,
            css: tabItem.type === "information" ? "info" : "",
            content: tabItem.content,
            element: tabItem.tabs?.length ? null : tabItem,
            dynamicTabs: tabItem.dynamicTabs ?? null,
            tabs: tabItem.tabs
              ?.filter((item) => ["subList", "subTabs", "subItem", "custom", "information", "title", "gallery"].includes(item.type))
              .map((subTabItem, index3) => ({
                name: `${subTabItem.id}-${index}-${index2}-${index3}`,
                title: subTabItem.title,
                type: subTabItem.type,
                icon: subTabItem.icon,
                css: subTabItem.type === "information" ? "info" : "",
                element: subTabItem,
                content: subTabItem.content,
              })),
          })),
      }));
    showInfo &&
      tempTab.unshift({
        name: `information-${openData.data._id}`,
        title: `${parentName} Details`,
        icon: "info",
        css: "info",
        type: showInfoType === "edit" ? "edit" : "details",
        element: editData,
        content: { titleValue, updatePrivilege, isEditingHandler, udpateView, formMode },
      });
    setTabs(tempTab);
  }, [formMode, isEditingHandler, titleValue, udpateView, updatePrivilege, showInfo, showInfoType, editData, parentName, openData]);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    tabHandler();
  }, [tabHandler]);
  return (
    <Overlay className={isSingle ? "plain" : ""}>
      <Page className={`${isSingle ? "plain" : ""} ${popupMode ?? "medium"} popup-child`}>
        {!isSingle && (
          <Header className={`parent ${popupMenu}`}>
            <Logo src={mobLogo} alt="logo" />
            <HeaderBox className="header-data">
              <div>
                {profileImage ? (
                  <ProfileImage className="full">
                    <img
                      src={openData?.data[profileImage] ? (customProfileSource ? "" : process.env.REACT_APP_CDN) + openData?.data[profileImage] : noimage}
                      onError={(e) => {
                        e.target.src = noimage; // Hide the image on error
                      }}
                      alt="Profile"
                    ></img>
                  </ProfileImage>
                ) : (
                  <GetIcon icon={parentIcon ?? selectedMenuItem.icon}></GetIcon>
                )}
                {/* <div>
                  <span> {`${getValue(itemTitle, titleValue)}`}</span>
                  <span> {getValue(itemDescription, descriptionValue)}</span>
                </div> */}
                <PageHeader title={getValue(itemTitle, titleValue)} line={false} description={getValue(itemDescription, descriptionValue)}></PageHeader>
              </div>
              <IconButton icon="back" theme={themeColors} ClickEvent={closeModal}></IconButton>
            </HeaderBox>
          </Header>
        )}
        <RowContainer theme={themeColors} className="popup-data">
          {tabs.length > 0 && <Tabs parentReference={parentReference} setLoaderBox={setLoaderBox} setMessage={setMessage} parents={parents} editData={editData} openData={openData} popupMenu={popupMenu} tabs={tabs}></Tabs>}
        </RowContainer>
      </Page>
    </Overlay>
  );
};
export default Popup;
