import React, { lazy, Suspense } from "react";

const Menu = lazy(() => import("../pages/menu/index.js"));
const Franchise = lazy(() => import("../pages/franchise/index.js"));
const Login = lazy(() => import("../../public/login/index.js"));
const Page404 = lazy(() => import("../pages/page404/index.js"));
const UserType = lazy(() => import("../pages/user/userType/index.js"));
const UserList = lazy(() => import("../pages/user/userList/index.js"));

const Meal = lazy(() => import("../pages/meal/index.js"));
const OrderStatus = lazy(() => import("../pages/dispatch/orderStatus/index.js"));
const ProteinCategory = lazy(() => import("../pages/mealSettings/proteinCategory/index.js"));
const MealTimeCategory = lazy(() => import("../pages/mealSettings/mealTimeCategory/index.js"));
const DayOfWeek = lazy(() => import("../pages/mealSettings/dayOfWeek/index.js"));
const AimOfProgram = lazy(() => import("../pages/registrationSettings/aimOfProgram/index.js"));
const MedicalCondition = lazy(() => import("../pages/registrationSettings/medicalCondition/index.js"));
const FoodlikeList = lazy(() => import("../pages/registrationSettings/foodlikeList/index.js"));
const FoodDislikeList = lazy(() => import("../pages/registrationSettings/foodDislikeList/index.js"));
const AddictionList = lazy(() => import("../pages/registrationSettings/addictionList/index.js"));
const DeliverySlot = lazy(() => import("../pages/dispatch/deliverySlot/index.js"));
const DeliveryInstruction = lazy(() => import("../pages/dispatch/deliveryInstruction/index.js"));
const TypeOfDiet = lazy(() => import("../pages/diet/typeOfDiet/index.js"));
const WeeklyMealPlan = lazy(() => import("../pages/mealSettings/weeklyMealPlan/index.js"));
const RedeemCoupen = lazy(() => import("../pages/dispatch/redeemCoupon/index.js"));
const Patient = lazy(() => import("../pages/user/patient/index.js"));
const MedicationList = lazy(() => import("../pages/registrationSettings/medicationList/index.js"));
const GeneralInstruction = lazy(() => import("../pages/registrationSettings/generalInstruction/index.js"));
const PackageManagement = lazy(() => import("../pages/packageManagement/index.js"));
const Supplement = lazy(() => import("../pages/registrationSettings/supplement/index.js"));
const DeliveryStatus = lazy(() => import("../pages/dispatch/deliveryStatus/index.js"));
const CuisineCategory = lazy(() => import("../pages/mealSettings/cuisineCategory/index.js"));
const ActivenessStatus = lazy(() => import("../pages/registrationSettings/activenessStatus/index.js"));
const DiscountTypeName = lazy(() => import("../pages/dispatch/discountType/index.js"));
const Banner = lazy(() => import("../pages/banner/banner/index.js"));
const BannerType = lazy(() => import("../pages/banner/bannerType/index.js"));
const MealIngredient = lazy(() => import("../pages/mealSettings/mealIngredient/index.js"));
const Dietician = lazy(() => import("../pages/user/dietician/index.js"));
const Deliveryman = lazy(() => import("../pages/dispatch/deliveryman/index.js"));
const Ingredient = lazy(() => import("../pages/mealSettings/Ingredient/ingredient.js"));
const VehicleCategory = lazy(() => import("../pages/dispatch/vechileCategory/index.js"));
const AdmissionRecord = lazy(() => import("../pages/user/admissionRecord/admissionRecord.js"));
const AppointmentHistory = lazy(() => import("../pages/user/appointment/appointmentHistory.js"));
const ActiveAppointment = lazy(() => import("../pages/user/appointment/activeAppointment.js"));
const OrderList = lazy(() => import("../pages/order/orderList.js"));
const TodayOrder = lazy(() => import("../pages/order/todayOrder.js"));
const AdmissionHistory = lazy(() => import("../pages/user/admissionHistory/admissionHistory.js"));
const ActiveAdmission = lazy(() => import("../pages/user/admissionHistory/activeAdmission.js"));
const Dashboard = lazy(() => import("../pages/dashboard/index.js"));
const Subscriber = lazy(() => import("../pages/package/subscriber.js"));
const Notification = lazy(() => import("../pages/notification/notification.js"));
const Blog = lazy(() => import("../pages/blog/blogs/index.js"));
const BlogCategory = lazy(() => import("../pages/blog/blogCategory/index.js"));
const SocialMedia = lazy(() => import("../pages/socialPlugins/socialMedia/index.js"));
const PageSettings = lazy(() => import("../pages/settings/pageSettings/index.js"));
const Admin = lazy(() => import("../pages/franchise/admin.js"));
const Student = lazy(() => import("../pages/user/student/student.js"));
const WeeklyMealPlanEntry = lazy(() => import("../pages/mealSettings/weeklyMealPlanEntry/weeklyMealPlanEntry.js"));
const Faq = lazy(() => import("../pages/socialPlugins/faq/index.js"));
const TomorrowOrder = lazy(() => import("../pages/order/tomorrowOrder.js"));
const FoodPackage = lazy(() => import("../pages/mealSettings/foodPackage/foodPackage.js"));
const FoodGroupItems = lazy(() => import("../pages/mealSettings/foodGroupItems/foodGroupItems.js"));
const Nationality = lazy(() => import("../pages/registrationSettings/nationality/index.js"));
const FoodMenu = lazy(() => import("../pages/mealSettings/foodMenu/index.js"));
const Recipe = lazy(() => import("../pages/mealSettings/recipe/recipe.js"));
const Variant = lazy(() => import("../pages/recipe/variant.js"));
const RecipeTag = lazy(() => import("../pages/mealSettings/recipeTag/recipeTag.js"));
const AddRecipe = lazy(() => import("../pages/mealSettings/addRecipe/index.js"));
const DaySlot = lazy(() => import("../pages/daySlot/index.js"));
const PackageOrder = lazy(() => import("../pages/order/packageOrder.js"));
const AvailableCalories = lazy(() => import("../pages/Calories/availableCalories.js"));
const Country = lazy(() => import("../pages/country/index.js"));
const PlanCategory = lazy(() => import("../pages/packageManagement/planCategory.js"));
const FoodExchangeServing = lazy(() => import("../pages/Calories/FoodExchangeServing.js"));
const FoodexchangeCalori = lazy(() => import("../pages/Calories/foodExchangeCalori.js"));
const FoodGroup = lazy(() => import("../pages/Calories/foodGroup.js"));
const AvailableSizes = lazy(() => import("../pages/Calories/availableSizes.js"));
const DietPrice = lazy(() => import("../pages/Calories/dietPrice.js"));
const DeliveryManLocation = lazy(() => import("../pages/dispatch/deliveryManLocation/index.js"));
const AddMeal = lazy(() => import("../pages/mealSettings/addMeal/index.js"));
const Recipes = lazy(() => import("../pages/mealSettings/recipes/index.js"));
const RecipeReport = lazy(() => import("../pages/report/recipeReport.js"));
const BirthdayReport = lazy(() => import("../pages/report/birthdayReport.js"));
const WeddingdayReport = lazy(() => import("../pages/report/weddingdayReport.js"));
const NewPatientTodayReport = lazy(() => import("../pages/report/newPatientTodayReport.js"));
const DietCentreBranch = lazy(() => import("../pages/registrationSettings/dietCentreBranch/index.js"));
const ErrorLog = lazy(() => import("../pages/errorLog/index.js"));
const IngredientReport = lazy(() => import("../pages/report/ingredientReport.js"));
const RestoreDeliveryMan = lazy(() => import("../pages/restoreUser/restoreDeliveryMan.js"));
const RestorePatient = lazy(() => import("../pages/restoreUser/restorePatient.js"));
const RestoreDietitian = lazy(() => import("../pages/restoreUser/restoreDietitian.js"));
const RestoreFranchiseAdmin = lazy(() => import("../pages/restoreUser/restoreFranchiseAdmin.js"));
const Allergy = lazy(() => import("../pages/allergy/index.js"));
const Inventory = lazy(() => import("../pages/inventory/index.js"));
const UserLog = lazy(() => import("../pages/report/userLog.js"));
const Delivery = lazy(() => import("../pages/order/delivery/index.js"));
const Packaging = lazy(() => import("../pages/order/packaging/index.js"));
const Preparation = lazy(() => import("../pages/order/preparation/index.js"));
const Aibot = lazy(() => import("../pages/order/aibot/index.js"));
const LabelPrintSetting = lazy(() => import("../pages/labelPrintSetting/index.js"));
const FixedReplacableRecipe = lazy(() => import("../pages/fixedReplacableRecipe/index.js"));
const InvoiceSetting = lazy(() => import("../pages/InvoiceSetting/index.js"));
const Invoice = lazy(() => import("../pages/packageManagement/invoice.js"));
const Elements = lazy(() => import("../pages/settings/elements/index.js"));
const Measurement = lazy(() => import("../pages/measurement/index.js"));
const AddFile = lazy(() => import("../pages/addFile/index.js"));
const FamilyHistory = lazy(() => import("../pages/registrationSettings/familyHistory/index.js"));
const PatientInfo = lazy(() => import("../pages/user/patientInfo/index.js"));
const Configuration = lazy(() => import("../pages/configuration/index.js"));
const Settings = lazy(() => import("../../core/settings/index.js"));
const ActivityLog = lazy(() => import("../pages/activityLog/index.js"));
const Wallet = lazy(() => import("../pages/wallet/index.js"));
const BlockOrBuilding = lazy(() => import("../pages/blockOrBuilding/index.js"));
const Appointment = lazy(() => import("../pages/appointment/index.js"));
const CustomBookAppointment = lazy(() => import("../pages/bookAppoiment/customPage.js"));
const BookAppointment = lazy(() => import("../pages/bookAppoiment/index.js"));

const RenderPage = (page, key, user, privileges) => {
  const renderComponent = (Component) => (
    <Suspense>
      <Component key={key} {...privileges} />
    </Suspense>
  );
  switch (page) {
    case "login":
      return renderComponent(Login);
    case "menu":
      return renderComponent(Menu);
    case "franchise":
      return renderComponent(Franchise);
    case "recepe":
      return renderComponent(Meal);
    case "user-role":
      return renderComponent(UserType);
    case "user-list":
      return renderComponent(UserList);
    case "delivery-slot":
      return renderComponent(DeliverySlot);
    case "protein-category":
      return renderComponent(ProteinCategory);
    case "delivery-instruction":
      return renderComponent(DeliveryInstruction);
    case "type-of-diet":
      return renderComponent(TypeOfDiet);
    case "order-status":
      return renderComponent(OrderStatus);
    case "mealtime-category":
      return renderComponent(MealTimeCategory);
    case "recipe-tag":
      return renderComponent(RecipeTag);
    case "day-of-week":
      return renderComponent(DayOfWeek);
    case "aimof-program":
      return renderComponent(AimOfProgram);
    case "medical-condition":
      return renderComponent(MedicalCondition);
    case "family-history":
      return renderComponent(FamilyHistory);
    case "foodlike-list":
      return renderComponent(FoodlikeList);
    case "fooddislike-list":
      return renderComponent(FoodDislikeList);
    case "addiction-list":
      return renderComponent(AddictionList);
    case "weekly-meal-plan":
      return renderComponent(WeeklyMealPlan);
    case "redeem-coupon":
      return renderComponent(RedeemCoupen);
    case "patient":
      return renderComponent(Patient);
    case "Cuisine-Category":
      return renderComponent(CuisineCategory);
    case "medication-list":
      return renderComponent(MedicationList);
    case "activeness-Status":
      return renderComponent(ActivenessStatus);
    case "subscriptionPlans":
      return renderComponent(PackageManagement);
    case "discount-type":
      return renderComponent(DiscountTypeName);
    case "supplement":
      return renderComponent(Supplement);
    case "banner":
      return renderComponent(Banner);
    case "delivery-status":
      return renderComponent(DeliveryStatus);
    case "banner-type":
      return renderComponent(BannerType);
    case "meal-ingredient":
      return renderComponent(MealIngredient);
    case "recipe-ingredient":
      return renderComponent(Ingredient);
    case "dietician":
      return renderComponent(Dietician);
    case "delivery-man":
      return renderComponent(Deliveryman);
    case "add-recipe":
      return renderComponent(AddRecipe);
    case "vehicle-category":
      return renderComponent(VehicleCategory);
    case "admission-record":
      return renderComponent(AdmissionRecord);
    case "appointment":
      return renderComponent(AppointmentHistory);
    case "active-appointment":
      return renderComponent(ActiveAppointment);
    case "order-list":
      return renderComponent(OrderList);
    case "today-order":
      return renderComponent(TodayOrder);
    case "admission-history":
      return renderComponent(AdmissionHistory);
    case "dashboard":
      return renderComponent(Dashboard);
    case "blog":
      return renderComponent(Blog);
    case "subscriber":
      return renderComponent(Subscriber);
    case "blog-category":
      return renderComponent(BlogCategory);
    case "notification":
      return renderComponent(Notification);
    case "social-media":
      return renderComponent(SocialMedia);
    case "active-admission":
      return renderComponent(ActiveAdmission);
    case "page-settings":
      return renderComponent(PageSettings);
    case "weekly-meal-plan-entry":
      return renderComponent(WeeklyMealPlanEntry);
    case "admin":
      return renderComponent(Admin);
    case "students":
      return renderComponent(Student);
    case "faq":
      return renderComponent(Faq);
    case "tomorrow-order":
      return renderComponent(TomorrowOrder);
    case "food-group":
      return renderComponent(AddMeal);
    case "food-package":
      return renderComponent(FoodPackage);
    case "food-group-items":
      return renderComponent(FoodGroupItems);
    case "nationality":
      return renderComponent(Nationality);
    case "food-menu":
      return renderComponent(FoodMenu);
    case "recipe":
      return renderComponent(Recipe);
    case "variant":
      return renderComponent(Variant);
    case "day-slot":
      return renderComponent(DaySlot);
    case "package-orders":
      return renderComponent(PackageOrder);
    case "available-calories":
      return renderComponent(AvailableCalories);
    case "foodexchange-calories":
      return renderComponent(FoodExchangeServing);
    case "food-exchange-group":
      return renderComponent(FoodGroup);
    case "available-sizes":
      return renderComponent(AvailableSizes);
    case "diet-price":
      return renderComponent(DietPrice);
    case "foodexchange-value":
      return renderComponent(FoodexchangeCalori);
    case "delivery-location":
      return renderComponent(DeliveryManLocation);
    case "recipes":
      return renderComponent(Recipes);
    case "recipe-report":
      return renderComponent(RecipeReport);
    case "birthday-report":
      return renderComponent(BirthdayReport);
    case "weddingday-report":
      return renderComponent(WeddingdayReport);
    case "new-patient-on-today":
      return renderComponent(NewPatientTodayReport);
    case "diet-centre-branch":
      return renderComponent(DietCentreBranch);
    case "error-log":
      return renderComponent(ErrorLog);
    case "ingredient-report":
      return renderComponent(IngredientReport);
    case "restore-delivery-man":
      return renderComponent(RestoreDeliveryMan);
    case "restore-patient":
      return renderComponent(RestorePatient);
    case "restore-dietitian":
      return renderComponent(RestoreDietitian);
    case "restore-franchise-admin":
      return renderComponent(RestoreFranchiseAdmin);
    case "allergy":
      return renderComponent(Allergy);
    case "inventory":
      return renderComponent(Inventory);
    case "preparation":
      return renderComponent(Preparation);
    case "packaging":
      return renderComponent(Packaging);
    case "delivery":
      return renderComponent(Delivery);
    case "subscriber-log":
      return renderComponent(UserLog);
    case "food-exchange":
      return renderComponent(Aibot);
    case "label-print-setting":
      return renderComponent(LabelPrintSetting);
    case "fixed-replacable-recipe":
      return renderComponent(FixedReplacableRecipe);
    case "invoice-setting":
      return renderComponent(InvoiceSetting);
    case "country":
      return renderComponent(Country);
    case "planCategory":
      return renderComponent(PlanCategory);
    case "invoice":
      return renderComponent(Invoice);
    case "elements":
      return renderComponent(Elements);
    case "measurement":
      return renderComponent(Measurement);
    case "add-file":
      return renderComponent(AddFile);
    case "general-instruction":
      return renderComponent(GeneralInstruction);
    case "patient-info":
      return renderComponent(PatientInfo);
    case "configuration":
      return renderComponent(Configuration);
    case "settings":
      return renderComponent(Settings);
    case "activity-log":
      return renderComponent(ActivityLog);
    case "wallet":
      return renderComponent(Wallet);
    case "block":
      return renderComponent(BlockOrBuilding);
    case "appointment-new":
      return renderComponent(Appointment);
    case "custom-book-appointment":
      return renderComponent(CustomBookAppointment);
    case "book-appointment":
      return renderComponent(BookAppointment);
    default:
      return renderComponent(Page404);
  }
};

export default RenderPage;
